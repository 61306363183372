<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">All Records</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);" id="scrollableDivtest">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="search-tab-outer-scroll" >
                    <div>
                        <div>
                            <div class="voter-exit-box-outer mb-3" v-if="!showdataloader">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                               >
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Income From</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showdataloader" style="height: 200px">
                                <div class="custom-allrecords-spinner-loader">
                                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                    <span class="spinner-text">Loading Personal Info ...</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="voter-exit-box-outer mb-3" v-if="!showdataloader">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                               >
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Income From</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showdataloader" style="height: 200px">
                                <div class="custom-allrecords-spinner-loader">
                                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                    <span class="spinner-text">Loading Personal Info ...</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="voter-exit-box-outer mb-3" v-if="!showdataloader">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                               >
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Income From</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showdataloader" style="height: 200px">
                                <div class="custom-allrecords-spinner-loader">
                                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                    <span class="spinner-text">Loading Personal Info ...</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="voter-exit-box-outer mb-3" v-if="!showdataloader">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                               >
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Income From</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showdataloader" style="height: 200px">
                                <div class="custom-allrecords-spinner-loader">
                                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                    <span class="spinner-text">Loading Personal Info ...</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="voter-exit-box-outer mb-3" v-if="!showdataloader">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                               >
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        test
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Income From</label>
                                                    <div class="from-label-value text-capitalize">
                                                       test
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showdataloader" style="height: 200px">
                                <div class="custom-allrecords-spinner-loader">
                                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                                    <span class="spinner-text">Loading Personal Info ...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            prefillresponse: '',
            showdataloader: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.scrollbottom();
        }, 10000);
        
    },
    methods: {
        scrollbottom() {
            var scrollableDivtest = document.getElementById('scrollableDivtest');
            var bottomElement = scrollableDivtest.lastElementChild;
            bottomElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        },
    }
}
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    transition: 0.5s;
    animation: showdiv 2s linear;
}
@keyframes showdiv {
  0% {
   opacity: 0;
  }
  50% {
   opacity: 0.5;
  }
  100% {
   opacity: 1;
  }
 }
.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}

.dividerrowhide .p-divider {
    display: none;
}

.form-submit-btn.btn {
    background: #ef8080 0% 0% no-repeat padding-box;
    border-color: #de2525;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 15px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}
.occupation-label {
    color: #0d488b;
    font-size: 14px;
}
.occupation-value {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    padding: 5px 7px;
    border-radius: 3px;
    letter-spacing: 0.52px;
    color: #0d488b;
    font-weight: 600;
    font-size: 14px;
}
.blink-occupation-value {
    animation: blinker 1s linear infinite;
    opacity: 0;
}

@keyframes blinker {
    from,
    49.9% {
        opacity: 0;
    }

    50%,
    to {
        opacity: 1;
    }
}
.print-submit-btn.btn {
    background: #0d6efd 0% 0% no-repeat padding-box;
    border-color: #0d6efd;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 15px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.print-submit-btn:focus {
    box-shadow: none;
}
</style>